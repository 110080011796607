import * as R from 'ramda';

import { DEFECTS, DEFECTS_DESCRIPTION, DEFLATORS, DEFLATOR_DESCRIPTION } from '@@/ItemAndGrading/constants';

import { env } from '@/config/env';

import { PARAM_ORIGIN, ROUTES } from '@/constants';

export * from './google-analytics';
export * from './strings';

export const isEmptyOrNil = R.anyPass([R.isEmpty, R.isNil]);

export const concatMessageId = (prefix: string, text: string) => R.concat(prefix, text);

export const isNotEmpty = R.complement(R.isEmpty);

export const getCapacityAttbId = R.pipe(
  R.prop('attributes'),
  R.find(R.propEq('Memory', 'name')),
  R.propOr('', 'attributeId')
);

export const getObjCracked = R.pipe(R.prop('results'), R.find(R.propEq('CRACKED', 'result')));

export const filterCrackedImages = R.filter(R.propEq('CRACKED', 'result'));

export const getRouteWithOrigin = (route: ROUTES, origin: PARAM_ORIGIN) => `${route}?origin=${origin}`;

export const getDeflatorsAsString = (deflators?: DEFLATORS[]) => {
  if (!deflators) return '';

  return R.join(
    ', ',
    R.map((x) => DEFLATOR_DESCRIPTION[x], deflators)
  );
};

export const getDefectsAsString = (defects?: DEFECTS[]) => {
  if (!defects) return '';

  return R.join(
    ', ',
    R.map((x) => DEFECTS_DESCRIPTION[x], defects)
  );
};

export const isDevOrLocal = R.includes(env.DOJI_ENV, ['development', 'localhost']);

export const isLocalHost = R.equals(env.DOJI_ENV, 'localhost');

export const isProduction = R.equals(env.DOJI_ENV, 'production');
