import { useEffect } from 'react';

import { Query, useQuery } from '@tanstack/react-query';

import { ApiResponse } from '@/@types/api-response';

import { PHONE_PERSPECTIVE, PHONE_PERSPECTIVE_LOWER } from '@@/ItemAndGrading/constants';
import { FILE_CATEGORY, FILE_TYPE } from '@@/ItemAndGrading/entities/file';
import { Item } from '@@/ItemAndGrading/entities/item';
import ItemService from '@@/ItemAndGrading/services/item-service';

import { fetchKeys } from '@/constants/fetch-keys';

import { CurrentPhotoMap } from '@/view/grading/phone-photos';

type Props = {
  itemId: string;
  refetchInterval?:
    | number
    | false
    | ((query: Query<ApiResponse<Item>, Error, ApiResponse<Item>, string[]>) => number | false | undefined)
    | undefined;

  enabled?: boolean;
};

export const useFindItem = ({ itemId, refetchInterval = false, enabled = true }: Props) => {
  const { data, isSuccess, isLoading, refetch } = useQuery({
    queryKey: [fetchKeys.item.find, itemId],
    queryFn: () => ItemService.findItem(itemId),
    refetchInterval: refetchInterval,
    enabled: enabled,
  });

  return { itemData: data?.response, isItemSuccess: isSuccess, isItemLoading: isLoading, refetchItem: refetch };
};

export const useFuncGradingReport = ({ itemId, enabled = true }: { itemId: string; enabled?: boolean }) => {
  const { data, isLoading, refetch, isFetching, isSuccess } = useQuery({
    queryKey: ['fetch-report', itemId],
    queryFn: () => ItemService.getPhonecheckReport(itemId),
    enabled: enabled,
    retry: true,
  });

  return {
    funcGradingReport: data?.response,
    isFuncGradingLoading: isLoading,
    refetchFuncGrading: refetch,
    isFuncGradingFetching: isFetching,
    isFuncGradingSuccess: isSuccess,
  };
};

type MissingMap = {
  [key: string]: number;
};

export const useGetItemImages = ({ itemId, enabled = true }: { itemId: string; enabled?: boolean }) => {
  const { data, isLoading, isSuccess } = useQuery({
    queryKey: [fetchKeys.phonePhoto.getItemImage, itemId],
    queryFn: () => ItemService.getImages(itemId),
    enabled: enabled,
  });

  const missingMap: MissingMap = {
    [PHONE_PERSPECTIVE.FRONT]: 0,
    [PHONE_PERSPECTIVE.BACK]: 0,
    [PHONE_PERSPECTIVE.LATERAL]: 0,
  };

  const currentPhotosMap: CurrentPhotoMap = {
    [PHONE_PERSPECTIVE.FRONT]: [],
    [PHONE_PERSPECTIVE.BACK]: [],
    [PHONE_PERSPECTIVE.LATERAL]: [],
  };

  const newMissingMap = missingMap;

  useEffect(() => {
    if (data?.response && isSuccess) {
      const { images, missingImages } = data.response;

      images.forEach(({ perspective, image }) => {
        currentPhotosMap[perspective].push({
          id: image.id,
          url: image.url,
          fileName: image.id,
          type: FILE_TYPE.IMAGE,
          category: FILE_CATEGORY.ITEM,
          description: perspective,
        });
      });

      Object.keys(missingImages).forEach((key) => {
        newMissingMap[key.toUpperCase()] = missingImages[key as PHONE_PERSPECTIVE_LOWER];
      });
    }
  }, [data?.response, isSuccess]);

  return {
    itemImages: data?.response,
    isItemImagesLoading: isLoading,
    isItemImagesSuccess: isSuccess,
    currentPhotosMap,
    newMissingMap,
  };
};
