import { ApiResponse } from '@/@types/api-response';

import { StoreOperator } from '@@/StoreAndStoreOperator/entities/store-operator';

import { tradeInApi } from '@/config/api';

export default class StoreOperatorService {
  static async getMe() {
    const { data } = await tradeInApi.get<ApiResponse<StoreOperator>>(`/v1/secure/store-operators/me`);
    return data;
  }
}
