import { IntlProvider } from 'react-intl';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { TradeInProvider } from '@/context/use-trade-in-provider';
import { UserAuthProvider } from '@/context/user-auth-provider';

import localesMessages from '@/locales';

import { LOCALE } from '@/constants/locales';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

type Props = {
  queryClient?: QueryClient;
};

const AppProvider: React.FCWC<Props> = ({ children, queryClient }) => {
  return (
    <IntlProvider locale={LOCALE.BR} messages={localesMessages[LOCALE.BR]}>
      <QueryClientProvider client={queryClient ?? client}>
        <UserAuthProvider>
          <TradeInProvider>{children}</TradeInProvider>
        </UserAuthProvider>
      </QueryClientProvider>
    </IntlProvider>
  );
};

export default AppProvider;
