enum SignUpError {
  NOT_ALLOWED_ACCESS = 'not_allowed_access',
  PASSWORD_PWNED = 'form_password_pwned',
  EMAIL_ALREADY_IN_USE = 'form_identifier_exists',
}

enum LoginError {
  PASSWORD_INCORRECT = 'form_password_incorrect',
  INVALID_FORM_FORMAT = 'form_param_format_invalid',
  ACCOUNT_NOT_FOUND = 'form_identifier_not_found',
}

export enum CodeError {
  INVALID_CODE = 'form_code_incorrect',
  EXPIRED_CODE = 'verification_expired',
}

type Error = {
  code: SignUpError & LoginError;
};

export type ClerkError = {
  errors: Array<Error>;
};

export enum CLERK_TEMPLATES {
  DEFAULT = 'default',
}

export const ClerkErrorToMessage = {
  [SignUpError.NOT_ALLOWED_ACCESS]: 'E-mail não foi convidado para nenhuma loja.',
  [SignUpError.PASSWORD_PWNED]: 'Esta senha já foi exposta em um vazamento de dados. Por favor, escolha uma diferente.',
  [SignUpError.EMAIL_ALREADY_IN_USE]: 'E-mail já cadastrado.',
  [LoginError.PASSWORD_INCORRECT]: 'Senha incorreta.',
  [LoginError.INVALID_FORM_FORMAT]: 'Esse e-mail não é válido.',
  [LoginError.ACCOUNT_NOT_FOUND]: 'Conta com esse e-mail não encontrada.',
  [CodeError.INVALID_CODE]: 'Código não é válido.',
  [CodeError.EXPIRED_CODE]: 'Código expirado, reenvie um novo código.',
};
