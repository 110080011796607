export enum FILE_CATEGORY {
  ITEM = 'ITEM',
  PRODUCT = 'PRODUCT',
  IMEI = 'IMEI',
  CONTRACT = 'CONTRACT',
}

export enum FILE_TYPE {
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT',
}

export type DojiFile = {
  id: string;
  url?: string;
  category: FILE_CATEGORY;
  type: FILE_TYPE;
  fileName?: string;
  description?: string;
  name?: string;
  path?: string;
  userId?: string;
  bucket?: string;
  isPublic?: boolean;
  created?: string;
  updated?: string | null;
  extension?: string;
  folder?: string;
  nameWithoutExtension?: string;
};

export type ItemImage = DojiFile;
