import { useMutation, useQuery } from '@tanstack/react-query';

import { useUserAuth } from '@/context/user-auth-provider';

import { TradeIn } from '@@/TradeIn/entities/trade-in';
import TradeInService, { UpdateTradeInRequest } from '@@/TradeIn/services/trade-in-service';

import { fetchKeys } from '@/constants/fetch-keys';

export const useUpdateTradeIn = ({
  tradeInId,
  setTradeInData,
}: {
  tradeInId: string;
  setTradeInData: (x: TradeIn) => void;
}) => {
  const { storeData } = useUserAuth();

  const { mutate: updateTradeIn, isPending: isUpdateTradeInPending } = useMutation({
    mutationFn: (data: UpdateTradeInRequest) =>
      TradeInService.updateTradeIn(tradeInId, storeData?.id as string, data as UpdateTradeInRequest),
    onSuccess: (data) => {
      setTradeInData(data.response);
    },
  });

  return { updateTradeIn, isUpdateTradeInPending };
};

export const useFindTradeIn = ({ tradeInId, enabled = true }: { tradeInId: string; enabled?: boolean }) => {
  const { data, isLoading, isSuccess, refetch, isRefetching } = useQuery({
    queryKey: [fetchKeys.tradeIn.find, tradeInId],
    queryFn: () => TradeInService.findById(tradeInId),
    enabled: !!tradeInId && enabled,
  });

  return {
    tradeInData: data?.response,
    isTradeInLoading: isLoading,
    isTradeInSuccess: isSuccess,
    refetchTradeIn: refetch,
    isRefetchingTradeIn: isRefetching,
  };
};

export const useGenerateContract = ({ tradeInId }: { tradeInId: string }) => {
  const { data, isLoading, isSuccess } = useQuery({
    queryKey: [fetchKeys.tradeIn, tradeInId],
    queryFn: () => TradeInService.generateContract(tradeInId),
    enabled: !!tradeInId,
  });

  return { contractData: data, isContractLoading: isLoading, isContractSuccess: isSuccess };
};

export const useGetContracts = ({ tradeInId }: { tradeInId: string }) => {
  const {
    data: contractsData,
    isSuccess: isContractSuccess,
    isLoading: isContractLoading,
  } = useQuery({
    enabled: !!tradeInId,
    queryKey: [fetchKeys.tradeIn.contract, tradeInId],
    queryFn: () => TradeInService.getContracts(tradeInId),
  });

  return { contractsData, isContractSuccess, isContractLoading };
};
