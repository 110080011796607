import { ApiResponse } from '@/@types/api-response';

import { Contract } from '@@/TradeIn/entities/contract';
import { TradeIn } from '@@/TradeIn/entities/trade-in';

import { tradeInApi } from '@/config/api';

export type ProductPriceResponse = {
  productDetails: {
    condition: string;
    title: string;
  };
  priceWithPitziInsurance: number;
  price: number;
};

export type GetDowngradedPricesResponse = ProductPriceResponse & {
  previousAcceptedInsurance: boolean;
};

export type UpdateTradeInRequest = {
  customer?: {
    name?: string;
    document?: {
      type: string;
      value: string;
    };
    email?: string;
    phone?: string;
    refId?: string;
    address?: {
      zipcode: string;
      address: string;
      number: string;
      addressLine2?: string;
      neighborhood: string;
      city: string;
      state: string;
    };
  };
  sponsorshipId?: string | null;
  acceptedBoost?: boolean;
  newProductPrice?: number | null;
};

export type CreateTradeInRequest = {
  name: string;
  document: {
    type: string;
    value: string;
  };
  email: string;
  phone: string;
  itemId: string;
  storeId: string;
  optInsurance: boolean | null;
  newProductPrice: number | null;
  refId?: string;
  address?: {
    zipcode: string;
    address: string;
    number: string;
    addressLine2?: string;
    neighborhood: string;
    city: string;
    state: string;
  };
};

type AcceptTradeInRequest = {
  name: string;
  document: {
    type: string;
    value: string;
  };
  email: string;
  phone: string;
  gradeSurveyId: string;
  storeId: string;
  optInsurance: boolean | null;
  newProductPrice: number | null;
};

export type FinalizeTradeInParams = {
  storeId: string;
  frontPhoto: File;
  backPhoto: File;
};

export default class TradeInService {
  static async getDowngradedPrices(tradeInId: string) {
    const { data } = await tradeInApi.get<ApiResponse<GetDowngradedPricesResponse>>(
      `/v1/secure/trade-ins/${tradeInId}/downgrade/prices`
    );

    return data;
  }

  static async updateTradeIn(tradeInId: string, storeId: string, requestBody: UpdateTradeInRequest) {
    const { data } = await tradeInApi.put<ApiResponse<TradeIn>>(`/v1/secure/trade-ins/${tradeInId}`, requestBody, {
      headers: {
        'Doji-Store-Id': storeId,
      },
    });

    return data;
  }

  static async postSecureAcceptTradeIn(storeId: string, requestBody: AcceptTradeInRequest) {
    const { data } = await tradeInApi.post<ApiResponse<TradeIn>>(`/v1/secure/trade-ins`, requestBody, {
      headers: {
        'Doji-Store-Id': storeId,
      },
    });
    return data;
  }

  static async postCreate(storeId: string, requestBody: CreateTradeInRequest) {
    const { data } = await tradeInApi.post<ApiResponse<TradeIn>>(`/v1/secure/trade-ins`, requestBody, {
      headers: {
        'Doji-Store-Id': storeId,
      },
    });
    return data;
  }

  static async postSecureCreateV2(storeId: string, requestBody: { itemId: string }) {
    const { data } = await tradeInApi.post<ApiResponse<TradeIn>>(`/v2/secure/trade-ins`, requestBody, {
      headers: {
        'Doji-Store-Id': storeId,
      },
    });
    return data;
  }

  static async postPublicCreate(storeId: string, requestBody: CreateTradeInRequest) {
    const { data } = await tradeInApi.post<ApiResponse<TradeIn>>(`/v1/public/trade-ins`, requestBody, {
      headers: {
        'Doji-Store-Id': storeId,
      },
    });
    return data;
  }

  static async getPublicGradedProductPriceByGradeSurveyIdAndStoreId(
    gradeSurveyId: string,
    storeId: string,
    newProductPrice?: number
  ) {
    const requestWithNewPrice = `/v1/secure/stores/${storeId}/prices?gradeSurveyId=${gradeSurveyId}&newProductPrice=${newProductPrice}`;
    const requestWithoutNewPrice = `/v1/secure/stores/${storeId}/prices?gradeSurveyId=${gradeSurveyId}`;

    const { data } = await tradeInApi.get<ApiResponse<ProductPriceResponse>>(
      newProductPrice ? requestWithNewPrice : requestWithoutNewPrice
    );

    return data;
  }

  static async postSendContractSignature(tradeInId: string, signature: string) {
    const { data } = await tradeInApi.post<ArrayBuffer>(
      `/v1/secure/trade-ins/${tradeInId}/signed-contract`,
      {
        signature,
      },
      {
        // This is necessary for it works
        headers: {
          Accept: '*/*',
        },
        responseType: 'arraybuffer',
      }
    );

    return data;
  }

  static async getContracts(tradeInId: string) {
    const { data } = await tradeInApi.get<ApiResponse<Contract>>(`/v1/secure/trade-ins/${tradeInId}/contract`);

    return data;
  }

  static async generateContract(tradeInId: string) {
    const { data } = await tradeInApi.post<ArrayBuffer>(
      `/v1/secure/trade-ins/${tradeInId}/contract`,
      {},
      {
        // This is necessary for it works
        headers: {
          Accept: '*/*',
        },
        responseType: 'arraybuffer',
      }
    );

    return data;
  }

  static async postFinalize(tradeInId: string, storeId: string) {
    const { data } = await tradeInApi.post<{ success: boolean; message: string }>(
      `/v1/secure/trade-ins/${tradeInId}/finalize`,
      {
        storeId,
      }
    );

    return data;
  }

  static async postTriggerAICosmeticGrade(itemId: string) {
    const { data } = await tradeInApi.post<ApiResponse<null>>(`/v1/secure/items/${itemId}/images/confirm`);

    return data;
  }

  static async postDowngrade(tradeInId: string) {
    const { data } = await tradeInApi.post<ApiResponse<TradeIn>>(`/v2/secure/trade-ins/${tradeInId}/downgrade`);

    return data;
  }

  static async findById(tradeInId: string) {
    const { data } = await tradeInApi.get<ApiResponse<TradeIn>>(`/v1/secure/trade-ins/${tradeInId}`);

    return data;
  }
}
