import { z } from 'zod';

const envSchema = z.object({
  DOJI_ENV: z.string().optional(),
  BACKEND_ADDRESS: z.string().optional(),
  TRADEIN_SOCKET_ADDRESS: z.string().optional(),
  TRADEIN_BACKEND_ADDRESS: z.string().optional(),
  TRADEIN_ADMIN_ADDRESS: z.string().optional(),
  GOOGLE_ANALYTICS_ENABLE: z.coerce.boolean().optional(),
  GOOGLE_ANALYTICS_ID: z.string().optional(),
  CLERK_PUBLISHABLE_KEY: z.string().optional().default('secret'),
  DATADOG_LOGS_CLIENT_TOKEN: z.string().optional(),
  CRISP_WEBSITE_ID: z.string().optional(),
  HOTJAR_SITE_ID: z.string().optional(),
  HOTJAR_VERSION: z.string().optional(),
});

export const env = envSchema.parse({
  DOJI_ENV: process.env.NEXT_PUBLIC_DOJI_ENV,
  BACKEND_ADDRESS: process.env.NEXT_PUBLIC_BACKEND_ADDRESS,
  TRADEIN_SOCKET_ADDRESS: process.env.NEXT_PUBLIC_TRADEIN_SOCKET_ADDRESS,
  TRADEIN_BACKEND_ADDRESS: process.env.NEXT_PUBLIC_TRADEIN_BACKEND_ADDRESS,
  TRADEIN_ADMIN_ADDRESS: process.env.NEXT_PUBLIC_TRADEIN_ADMIN_ADDRESS,
  GOOGLE_ANALYTICS_ENABLE: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ENABLE,
  GOOGLE_ANALYTICS_ID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
  CLERK_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
  DATADOG_LOGS_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_LOGS_CLIENT_TOKEN,
  CRISP_WEBSITE_ID: process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID,
  HOTJAR_SITE_ID: process.env.NEXT_PUBLIC_HOTJAR_SITE_ID,
  HOTJAR_VERSION: process.env.NEXT_PUBLIC_HOTJAR_VERSION,
});
