export enum DEFLATORS {
  SCREEN = 'SCREEN',
  BODY = 'BODY',
  SCRATCHED_SCREEN = 'SCRATCHED_SCREEN',
  SCRATCHED_BODY = 'SCRATCHED_BODY',
  BATTERY = 'BATTERY',
  CONNECTOR = 'CONNECTOR',
  CONNECTIVITY = 'CONNECTIVITY',
  CAMERA = 'CAMERA',
  COMPONENTS = 'COMPONENTS',
}

export const DEFLATOR_DESCRIPTION: { [key in DEFLATORS]: string } = {
  [DEFLATORS.SCREEN]: 'Tela Defeituosa',
  [DEFLATORS.BODY]: 'Carcaça Quebrada',
  [DEFLATORS.SCRATCHED_SCREEN]: 'Tela Riscada',
  [DEFLATORS.SCRATCHED_BODY]: 'Carcaça Riscada',
  [DEFLATORS.BATTERY]: 'Bateria',
  [DEFLATORS.CONNECTOR]: 'Conector',
  [DEFLATORS.CONNECTIVITY]: 'Conectividade',
  [DEFLATORS.CAMERA]: 'Câmera',
  [DEFLATORS.COMPONENTS]: 'Componentes',
};

export enum DEFECTS {
  CONNECTIVITY = 'CONNECTIVITY',
  SENSOR = 'SENSOR',
  BUTTON = 'BUTTON',
  VIBRATION = 'VIBRATION',
  FRONT_CAMERA = 'FRONT_CAMERA',
  REAR_CAMERA = 'REAR_CAMERA',
  DISPLAY = 'DISPLAY',
  SPEAKER = 'SPEAKER',
  CONNECTOR = 'CONNECTOR',
  MICROPHONE = 'MICROPHONE',
  BATTERY = 'BATTERY',
  BATTERY_HEALTH = 'BATTERY_HEALTH',
}

export const DEFECTS_DESCRIPTION: { [key in DEFECTS]: string } = {
  [DEFECTS.CONNECTIVITY]: 'Conectividade',
  [DEFECTS.SENSOR]: 'Sensores',
  [DEFECTS.BUTTON]: 'Botões',
  [DEFECTS.VIBRATION]: 'Vibração',
  [DEFECTS.FRONT_CAMERA]: 'Câmera Frontal',
  [DEFECTS.REAR_CAMERA]: 'Câmera Traseira',
  [DEFECTS.DISPLAY]: 'Tela',
  [DEFECTS.SPEAKER]: 'Alto-Falante',
  [DEFECTS.CONNECTOR]: 'Conectores',
  [DEFECTS.MICROPHONE]: 'Microfone',
  [DEFECTS.BATTERY]: 'Bateria',
  [DEFECTS.BATTERY_HEALTH]: 'Saúde da Bateria',
};
