import { useQuery } from '@tanstack/react-query';

import StoreService from '@@/StoreAndStoreOperator/services/store-service';

import { fetchKeys } from '@/constants/fetch-keys';

export const useSecureStores = ({
  enabled = true,
  refetchInterval,
}: {
  enabled: boolean;
  refetchInterval?: number | false;
}) => {
  const { data, isLoading, refetch, isRefetching, isSuccess } = useQuery({
    queryKey: [fetchKeys.store.authStoreData],
    queryFn: () => StoreService.getSecureStores(),
    // If retry true, failed queries will retry infinitely.
    retry: true,
    refetchOnMount: true,
    refetchInterval: refetchInterval,
    gcTime: 0,
    enabled: enabled,
  });

  return {
    storeData: data?.response,
    isStoreLoading: isLoading,
    refetchStore: refetch,
    isStoreRefetching: isRefetching,
    isStoreSuccess: isSuccess,
  };
};

export const usePublicStores = ({ storeId, enabled = true }: { storeId?: string; enabled?: boolean }) => {
  const { data, isSuccess, isError, isLoading } = useQuery({
    queryKey: [fetchKeys.grading.searchStoreData, storeId],
    queryFn: () => StoreService.getPublicStore(storeId as string),
    enabled: enabled,
  });
  return {
    storeData: data?.response,
    isStoreLoading: isLoading,
    isStoreSuccess: isSuccess,
    isStoreError: isError,
  };
};
