import { ApiResponse } from '@/@types/api-response';

import { BoostBid } from '@@/Pricing/entities/boost-bid';
import { CompetitorPrice } from '@@/Pricing/entities/competitor-price';

import { tradeInApi } from '@/config/api';

export type BestBidResponse = {
  bidderId: string;
  storePrice: number;
  storeBoostedPrice?: number | null;
  newProductPrice?: number | null;
  boosts?: BoostBid[];
};

export default class BidService {
  static async getBestBid(itemId: string, storeId: string, newProductPrice?: number) {
    const { data } = await tradeInApi.get<ApiResponse<BestBidResponse>>(`/v1/secure/items/${itemId}/bids/best`, {
      params: {
        newProductPrice,
      },
      headers: {
        'Doji-Store-Id': storeId,
      },
    });

    return data;
  }

  static async publicBestBid(itemId: string, storeId: string) {
    const { data } = await tradeInApi.get<ApiResponse<BestBidResponse>>(`/v1/public/items/${itemId}/bids/best`, {
      headers: {
        'Doji-Store-Id': storeId,
      },
    });

    return data;
  }

  static async getCompetitorsPrice(itemId: string) {
    const { data } = await tradeInApi.get<ApiResponse<CompetitorPrice[]>>(
      `/v1/secure/items/${itemId}/competitor/bids/best`
    );

    return data;
  }
}
