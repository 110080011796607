import { useUser } from '@clerk/nextjs';

import { USER_ROLES } from '@@/Authentication/constants';

type Permission = {
  role: string;
  isStoreOperator: boolean;
  isStoreOperatorAdmin: boolean;
  orgId: string;
};

const isAdmin = (permissions: Permission[]) =>
  permissions?.length > 0 && permissions.some((permission) => permission.role === USER_ROLES.ADMIN);

const isStoreOperator = (permissions: Permission[]) =>
  permissions?.length > 0 && permissions.some((permission) => permission.isStoreOperator);

const isStoreOperatorAdmin = (permissions: Permission[]) =>
  permissions?.length > 0 && permissions.some((permission) => permission.isStoreOperatorAdmin);

export const useVerifyPermission = () => {
  const { user } = useUser();

  const permissions = user?.publicMetadata.permissions as Permission[];

  const isUserAdmin = isAdmin(permissions);

  const isOnlyAdmin = isAdmin(permissions) && !isStoreOperator(permissions) && !isStoreOperatorAdmin(permissions);

  return { isUserAdmin, isOnlyAdmin };
};
