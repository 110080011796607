export enum DEFECTS_STATUS {
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export enum GRADING_STEP {
  SELECT_ATTB = 'SELECT_ATTB',
  QUESTIONS = 'QUESTIONS',
}

export const enum PHONE_COSMETIC_CONDITION {
  SCRATCHED = 'SCRATCHED',
  CLEAN = 'CLEAN',
  CRACKED = 'CRACKED',
}
