import { env } from '@/config/env';

export const pageview = (url: string) => {
  const id = env.GOOGLE_ANALYTICS_ID;

  if (window?.gtag && id) {
    window.gtag('config', id, {
      page_path: url,
    });
  }
};

export const eventGa = (
  action: string,
  params?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams | undefined
) => {
  if (window?.gtag) {
    window.gtag('event', action, params);
  }
};
