export enum STORAGE_KEYS {
  DOJI_USER_DATA = '@Doji/user-data',
  SELECTED_STORE = '@Doji/selected-store',
  DAILY_TIP_LAST_VIEWED = '@Doji/daily-tip-last-viewed',
  DOJI_TRADE_IN_ID = '@Doji/trade-in-id',
  DOJI_ITEM_ID = '@Doji/item-id',
  DOJI_MODEL_ID = '@Doji/model-id',
  DOJI_SURVEY_ANSWERS = '@Doji/survey-answers',
  DOJI_CUSTOMER_REF_ID = '@Doji/customer-ref-id',
  DOJI_BUY_PRODUCT_PRICE = '@Doji/buy-product-price',
  DOJI_CUSTOMER_FORMS = '@Doji/customer-forms',
  DOJI_PRODUCT_NAME = '@Doji/product-name',
}

export const useLocalStorage = () => {
  if (typeof window === 'undefined') return;

  const setItem = (key: string, value: unknown) => localStorage.setItem(key, JSON.stringify(value));

  const getItem = (key: string) => JSON.parse(localStorage.getItem(key) as string);

  const removeItem = (key: string) => localStorage.removeItem(key);

  const clearStorage = () => localStorage.clear();

  const hasItem = (key: string) => localStorage.getItem(key) !== null;

  return { setItem, getItem, removeItem, clearStorage, hasItem };
};
