import { ApiResponse } from '@/@types/api-response';

import { Store, StorePublic } from '@@/StoreAndStoreOperator/entities/store';

import { tradeInApi } from '@/config/api';

export default class StoreService {
  static async getPublicStore(id: string) {
    const { data } = await tradeInApi.get<ApiResponse<StorePublic>>(`/v1/public/stores/${id}`);
    return data;
  }

  static async getSecureStores() {
    const { data } = await tradeInApi.get<ApiResponse<Store[]>>(`/v1/secure/stores`);
    return data;
  }
}
