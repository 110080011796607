import { useQuery } from '@tanstack/react-query';

import StoreOperatorService from '@@/StoreAndStoreOperator/services/store-operator-service';

import { fetchKeys } from '@/constants/fetch-keys';

export const useStoreOperatorService = () => {
  const {
    data,
    refetch: refetchStoreOperator,
    isSuccess: isStoreOperatorSuccess,
  } = useQuery({
    queryKey: [fetchKeys.user.data],
    queryFn: () => StoreOperatorService.getMe(),
    enabled: false,
  });

  return { refetchStoreOperator, storeOperatorMeData: data?.response, isStoreOperatorSuccess };
};
